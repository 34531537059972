<template>
  <div>
    <v-row>
      <v-col cols="6" v-if="false">
        <v-card flat class="grey lighten-5" height="100%">
          <v-card-subtitle class="overline grey--text lighten-5 py-2">
            Device
          </v-card-subtitle>
          <v-card-text>
            <span v-for="item in this.devices.slices" :key="item.id">
              <v-divider class="mt-3"></v-divider>
              <v-row align="center">
                <v-col cols="6">
                  <h5 class="font-weight-medium text--secondary">
                    {{ item.name }}
                  </h5>
                </v-col>
                <!-- <v-col cols="3" align="right">
                <h6>{{ item.percentage }} %</h6>
              </v-col> -->
                <v-col cols="6" align="right">
                  <h6>{{ item.percentage }} %</h6>
                  <v-progress-linear
                    rounded
                    :value="item.percentage"
                    height="7"
                    color="light-blue darken-4"
                  ></v-progress-linear>
                </v-col> </v-row
            ></span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat class="grey lighten-5" height="100%">
          <v-card-subtitle class="overline grey--text lighten-5 py-2">
            Inventory Size
          </v-card-subtitle>
          <v-card-text>
            <span
              v-for="item in this.audience_reach.inventory_size"
              :key="item.id"
            >
              <v-divider class="mt-3"></v-divider>
              <v-row align="center">
                <v-col cols="6">
                  <h5
                    class="font-weight-medium text--secondary text-capitalize"
                  >
                    {{ item.name }}
                  </h5>
                </v-col>
                <v-col cols="6" align="right">
                  <h6>{{ item.percentage }} %</h6>
                  <v-progress-linear
                    rounded
                    :value="item.percentage"
                    height="7"
                    color="light-blue"
                  ></v-progress-linear>
                </v-col> </v-row
            ></span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="grey lighten-5" height="100%">
          <v-card-subtitle class="overline grey--text lighten-5 py-2">
            Content Verticals
          </v-card-subtitle>
          <v-card-text>
            <span v-for="item in this.audience_reach.verticals" :key="item.id">
              <v-divider class="mt-3"></v-divider>
              <v-row align="center">
                <v-col cols="6">
                  <h5 class="font-weight-medium text--secondary">
                    {{ item.name }}
                  </h5>
                </v-col>
                <v-col cols="6" align="right">
                  <h6>{{ item.percentage }} %</h6>
                  <v-progress-linear
                    rounded
                    :value="item.percentage"
                    height="7"
                    color="light-blue darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import orderBy from "lodash.orderby";
import { cloneDeep } from "lodash-es";

export default {
  data: () => ({
    inventory_data: [],
    verticals: [],
    devices: [],
    options: [],
  }),
  computed: {
    audience_reach() {
      let audience = {};
      if (this.$route.name == "discovery") {
        if (this.country === "kenya") {
          audience = this.$store.state.dsp.kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.senegal_discovery_data.audience_reach;
        } else if (this.country === "uganda") {
          audience = this.$store.state.dsp.uganda_discovery_data.audience_reach;
        } else if (this.country === "angola") {
          audience = this.$store.state.dsp.angola_discovery_data.audience_reach;
        } else if (this.country === "gabon") {
          audience = this.$store.state.dsp.gabon_discovery_data.audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.ethiopia_discovery_data.audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.nigeria_discovery_data.audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.south_africa_discovery_data.audience_reach;
        } else if (this.country === "global") {
          audience = this.$store.state.dsp.global_discovery_data.audience_reach;
        }
      }
      if (this.$route.name == "truecaller-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.truecaller_kenya_discovery_data
              .audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.truecaller_senegal_discovery_data
              .audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.truecaller_uganda_discovery_data
              .audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.truecaller_angola_discovery_data
              .audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.truecaller_gabon_discovery_data
              .audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.truecaller_ethiopia_discovery_data
              .audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.truecaller_nigeria_discovery_data
              .audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.truecaller_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.truecaller_global_discovery_data
              .audience_reach;
        }
      }
      if (this.$route.name == "opera-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.opera_kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.opera_senegal_discovery_data.audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.opera_uganda_discovery_data.audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.opera_gabon_discovery_data.audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.opera_angola_discovery_data.audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.opera_ethiopia_discovery_data.audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.opera_nigeria_discovery_data.audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.opera_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.opera_global_discovery_data.audience_reach;
        }
      }
      if (this.$route.name == "transsion-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.transsion_kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.transsion_senegal_discovery_data
              .audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.transsion_uganda_discovery_data
              .audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.transsion_angola_discovery_data
              .audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.transsion_gabon_discovery_data
              .audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.transsion_ethiopia_discovery_data
              .audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.transsion_nigeria_discovery_data
              .audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.transsion_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.transsion_global_discovery_data
              .audience_reach;
        }
      }
      if (this.$route.name == "phoenix-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.phoenix_kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.phoenix_senegal_discovery_data.audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.phoenix_uganda_discovery_data.audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.phoenix_angola_discovery_data.audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.phoenix_gabon_discovery_data.audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.phoenix_ethiopia_discovery_data
              .audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.phoenix_nigeria_discovery_data.audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.phoenix_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.phoenix_global_discovery_data.audience_reach;
        }
      }
      if (this.$route.name == "gaming-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.gaming_kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.gaming_senegal_discovery_data
              .audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.gaming_uganda_discovery_data
              .audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.gaming_angola_discovery_data
              .audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.gaming_gabon_discovery_data
              .audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.gaming_ethiopia_discovery_data
              .audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.gaming_nigeria_discovery_data
              .audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.gaming_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.gaming_global_discovery_data
              .audience_reach;
        }
      }
      if (this.$route.name == "ctv-discovery") {
        if (this.country === "kenya") {
          audience =
            this.$store.state.dsp.ctv_kenya_discovery_data.audience_reach;
        } else if (this.country === "senegal") {
          audience =
            this.$store.state.dsp.ctv_senegal_discovery_data
              .audience_reach;
        } else if (this.country === "uganda") {
          audience =
            this.$store.state.dsp.ctv_uganda_discovery_data
              .audience_reach;
        } else if (this.country === "angola") {
          audience =
            this.$store.state.dsp.ctv_angola_discovery_data
              .audience_reach;
        }else if (this.country === "gabon") {
          audience =
            this.$store.state.dsp.ctv_gabon_discovery_data
              .audience_reach;
        } else if (this.country === "ethiopia") {
          audience =
            this.$store.state.dsp.ctv_ethiopia_discovery_data
              .audience_reach;
        } else if (this.country === "nigeria") {
          audience =
            this.$store.state.dsp.ctv_nigeria_discovery_data
              .audience_reach;
        } else if (this.country === "south_africa") {
          audience =
            this.$store.state.dsp.ctv_south_africa_discovery_data
              .audience_reach;
        } else if (this.country === "global") {
          audience =
            this.$store.state.dsp.ctv_global_discovery_data
              .audience_reach;
        }
      }
      return audience;
    },
  },
  mounted() {
    //Inventory Bars
    this.inventory_data = this.developSideBarData("INVENTORY_SIZE");

    //Content Verticals
    this.verticals = this.developSideBarData("CONTENT_VERTICAL");

    //Devices
    this.devices = this.developSideBarData("DEVICE");
  },
  methods: {
    capitalizeFirstLetter(str) {
      // converting first letter to uppercase
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    },

    developSideBarData(category) {
      let values = cloneDeep(this.kenya_forecast);
      let data = values.breakdowns.find((w) => w.filterType === category);
      data.slices = data.slices.slice(0, 10);
      data.total = data.slices.reduce(
        (prev, next) => prev + parseInt(next.impressions),
        0
      );
      for (let i = 0; i < data.slices.length; i++) {
        let percentage = (
          (parseInt(data.slices[i].impressions) / data.total) *
          100
        ).toFixed(2);
        data.slices[i].percentage = percentage;
      }
      data.slices = orderBy(
        data.slices,
        [(c) => parseInt(c.percentage)],
        ["desc"]
      );
      return data;
    },
  },
  props: {
    kenya_forecast: {
      type: Object,
    },
    country: {
      type: String,
    },
  },
};
</script>

<style></style>
