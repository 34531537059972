<template>
  <v-row>
    <v-col cols="12">
      <v-card flat class="mb-5" min-height="200px">
        <v-card-title class="py-2">
          <h5>
            Inventory Forecast -
            {{ humanize(country) }}
          </h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <inventory-forecast
            :country="country"
            :kenya_forecast="kenya_forecast"
            v-if="kenya_forecast"
          />
        </v-card-text>
      </v-card>
      <v-card flat min-height="200px">
        <v-card-title class="py-2">
          <h5>Audience Reach</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <filter-inventory
            :country="country"
            :kenya_forecast="kenya_forecast"
            v-if="kenya_forecast"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <discovery-table :country="country" />
    </v-col>
  </v-row>
</template>

<script>
import DiscoveryTable from "../Tables/DiscoveryTable.vue";
import FilterInventory from "../Discovery/FilterInventory.vue";
import InventoryForecast from "../Discovery/InventoryForecast.vue";

export default {
  data() {
    return {};
  },
  components: {
    DiscoveryTable,
    FilterInventory,
    InventoryForecast,
  },
  props: {
    kenya_forecast: {
      type: Object,
    },
    country: {
      type: String,
    },
  },
  methods: {
    humanize(str) {
      let i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
};
</script>
