<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-card flat color="grey lighten-5" height="100%">
              <v-card-title class="overline grey--text">
                Impressions
              </v-card-title>
              <v-card-subtitle class="text-h5 black--text">{{
                impressions
              }}</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat color="grey lighten-5" height="100%">
              <v-card-title class="overline grey--text"> Uniques </v-card-title>
              <v-card-subtitle class="text-h5 black--text">
                {{ uniqueUsers }}</v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat color="grey lighten-5">
              <v-row no-gutters>
                <v-col cols="8">
                  <v-card-title class="overline grey--text">
                    Gender
                  </v-card-title>
                  <v-card-subtitle class="black--text text-caption">
                    <div class="d-flex align-center">
                      <v-icon left x-small color="blue">mdi-circle</v-icon
                      >{{ `Male: ${genderChart.series[0]}%` }}
                    </div>

                    <div class="d-flex align-center">
                      <v-icon left x-small color="purple">mdi-circle</v-icon
                      >{{ `Female: ${genderChart.series[1]}%` }}
                    </div>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-end"
                  ><apexchart
                    type="donut"
                    :options="genderChart"
                    :series="genderChart.series"
                    height="64"
                  ></apexchart>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat color="grey lighten-5">
              <v-row no-gutters>
                <v-col cols="8">
                  <v-card-title class="overline grey--text">
                    Format
                  </v-card-title>
                  <v-card-subtitle class="black--text text-caption">
                    <div class="d-flex align-center">
                      <v-icon left x-small color="blue">mdi-circle</v-icon
                      >{{ `Display: ${formatChart.series[0]}%` }}
                    </div>

                    <div class="d-flex align-center">
                      <v-icon left x-small color="purple">mdi-circle</v-icon
                      >{{ `Video: ${formatChart.series[1]}%` }}
                    </div>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-end"
                  ><apexchart
                    type="donut"
                    :options="formatChart"
                    :series="formatChart.series"
                    height="64"
                  ></apexchart>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-card flat color="grey lighten-5 ">
          <v-card-title class="overline grey--text pb-0"> Age </v-card-title>

          <v-card-text>
            <apexchart
              type="bar"
              height="10"
              :options="ageChart"
              :series="ageChart.series"
            ></apexchart>
          </v-card-text>
          <v-card-subtitle class="black--text text-caption">
            <div
              v-for="(age, index) in ageChart.series"
              :key="age.name"
              class="d-flex justify-space-between align-center"
            >
              <div>
                <v-icon left x-small :color="ageColors[index]"
                  >mdi-circle</v-icon
                >
                {{ age.name }}
              </div>
              <div>
                {{ `${age.data}%` }}
              </div>
            </div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card flat color="grey lighten-5">
          <v-card-title class="overline grey--text pb-0">
            Devices
          </v-card-title>
          <v-row no-gutters>
            <v-col>
              <div class="d-flex align-center justify-center">
                <apexchart
                  type="donut"
                  :options="deviceChart"
                  :series="deviceChart.series"
                ></apexchart>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import config from "@/apex-config";
import { compactNumber } from "@/utils/numberFormatter.js";

export default {
  props: {
    kenya_forecast: {
      type: Object,
    },
    country: {
      type: String,
    },
  },
  data: () => ({
    ageColors: ["blue", "purple", "red", "orange", "yellow", "green"],

    genderChart: {
      series: [],
      chart: {
        type: "donut",

        sparkline: {
          enabled: true,
        },
      },
      colors: [
        "#2196F3",
        "#9c27b0",
        "#F44336",
        "#ff9800",
        "#ffeb3b",
        "#4CAF50",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    //Request Format
    formatChart: {
      series: [],
      chart: {
        type: "donut",
        sparkline: {
          enabled: true,
        },
      },
      colors: [
        "#2196F3",
        "#9c27b0",
        "#F44336",
        "#ff9800",
        "#ffeb3b",
        "#4CAF50",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    deviceChart: {
      series: [],
      chart: {
        // height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: [
        "#2196F3",
        "#9c27b0",
        "#F44336",
        "#ff9800",
        "#ffeb3b",
        "#4CAF50",
      ],

      legend: {
        show: true,
        floating: true,
        // fontSize: "16px",
        position: "left",
        offsetX: -20,
        offsetY: -8,
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return (
            seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
          );
        },
        itemMargin: {
          vertical: 0,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    //age chart

    ageChart: {
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        sparkline: {
          enabled: true,
        },
      },
      colors: [
        "#2196F3",
        "#9c27b0",
        "#F44336",
        "#ff9800",
        "#ffeb3b",
        "#4CAF50",
      ],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      xaxis: {
        categories: ["Age"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
        },
      },
    },
  }),

  computed: {
    impressions() {
      return compactNumber(this.kenya_forecast.metrics.impressions);
    },
    uniqueUsers() {
      return compactNumber(this.kenya_forecast.metrics.uniqueUsers);
    },
  },

  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
    //Charts
    //Age Chart
    try {
      const data = this.kenya_forecast.breakdowns.find(
        ({ filterType }) => filterType === "AGE"
      );
      const chart_data = data.slices.map((impressions) => ({
        name: impressions.name,
        y: parseInt(impressions.impressions),
      }));

      //Totals Summation
      this.age_chart_total = chart_data.reduce(
        (total, obj) => obj.y + total,
        0
      );

      this.chartLabel = data.slices.map((value) => value.name);
      this.chartData = data.slices.map((value) => ({
        name: value.name,
        data: [
          parseFloat(
            (
              (parseInt(value.impressions) / this.age_chart_total) *
              100
            ).toFixed(2)
          ),
        ],
      }));

      this.ageChart.series = this.chartData;
      // this.ageChart = {
      //   ...this.ageChart,
      //   ...{
      //     xaxis: {
      //       categories: this.chartLabel,
      //     },
      //   },
      // };
    } catch (error) {
      this.error = error;
    }

    //Gender Chart
    try {
      const data = this.kenya_forecast.breakdowns.find(
        ({ filterType }) => filterType === "GENDER"
      );
      const chart_data = data.slices.map((value) => ({
        name: value.name,
        y: parseInt(value.impressions),
      }));

      //Totals Summation
      this.gender_chart_total = chart_data.reduce(
        (total, obj) => obj.y + total,
        0
      );

      this.chartLabel = data.slices.map((value) => value.name);
      this.chartData = data.slices.map((value) =>
        parseFloat(
          (
            (parseInt(value.impressions) / this.gender_chart_total) *
            100
          ).toFixed(2)
        )
      );
      this.genderChart.series = this.chartData;
      // console.log(this.genderChart.series);
      this.genderChart = {
        ...this.genderChart,
        ...{
          labels: this.chartLabel,
        },
      };
    } catch (error) {
      this.error = error;
    }

    //Device Chart
    try {
      const data = this.kenya_forecast.breakdowns.find(
        ({ filterType }) => filterType === "DEVICE"
      );
      const chart_data = data.slices.map((impressions) => ({
        name: impressions.name,
        y: parseInt(impressions.impressions),
      }));

      //Totals Summation
      this.device_chart_total = chart_data.reduce(
        (total, obj) => obj.y + total,
        0
      );
      this.chartLabel = data.slices.map((value) => value.name);

      this.chartData = data.slices.map((value) =>
        parseFloat(
          (
            (parseInt(value.impressions) / this.device_chart_total) *
            100
          ).toFixed(2)
        )
      );
      this.deviceChart.series = this.chartData;
      // console.log(this.genderChart.series);
      this.deviceChart = {
        ...this.deviceChart,
        ...{
          labels: this.chartLabel,
        },
      };
    } catch (error) {
      this.error = error;
    }

    //Format Chart
    try {
      const data = this.kenya_forecast.breakdowns.find(
        ({ filterType }) => filterType === "REQUEST_FORMAT"
      );
      const chart_data = data.slices.map((value) => ({
        name: value.name,
        y: parseInt(value.impressions),
      }));

      //Totals Summation
      this.format_chart_total = chart_data.reduce(
        (total, obj) => obj.y + total,
        0
      );

      this.chartLabel = data.slices.map((value) => value.name);
      this.chartData = data.slices.map((value) =>
        parseFloat(
          (
            (parseInt(value.impressions) / this.format_chart_total) *
            100
          ).toFixed(2)
        )
      );
      this.formatChart.series = this.chartData;
      // console.log(this.genderChart.series);
      this.formatChart = {
        ...this.formatChart,
        ...{
          labels: this.chartLabel,
        },
      };
    } catch (error) {
      this.error = error;
    }
  },
};
</script>
