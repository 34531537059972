<template>
  <div>
    <v-card flat>
      <v-card-title class="py-2"> <h5>Publishers</h5> </v-card-title
      ><v-divider></v-divider>

      <v-simple-table v-if="sitesAndApps">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Site & Apps</th>
              <th class="text-left">Impressions</th>
              <th class="text-left">Unique</th>
              <th class="text-left">Display</th>
              <th class="text-left">Video</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in sitesAndApps" :key="index">
              <td class="py-2">
                <span class="font-weight-bold" v-if="app['App name']"
                  >{{ app["App name"] }} <br /></span
                ><a>{{ app["#Domain/App ID"] }}</a>
              </td>
              <td>
                {{ compactNumber(app["Filtered Impressions"]) }}
              </td>
              <td>{{ compactNumber(app["Filtered Uniques"]) }}</td>
              <td>
                {{
                  (
                    (parseInt(app["Filtered Display Impressions"]) /
                      parseInt(app["Filtered Impressions"])) *
                    100
                  ).toFixed()
                }}
                %
              </td>
              <td>
                {{
                  (
                    (parseInt(app["Filtered Video Impressions"]) /
                      parseInt(app["Filtered Impressions"])) *
                    100
                  ).toFixed()
                }}
                %
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-linear indeterminate v-if="loading" />
      <v-card v-intersect="infiniteScrolling"></v-card>
    </v-card>
  </div>
</template>

<script>
import { compactNumber } from "@/utils/numberFormatter.js";

export default {
  data() {
    return {
      page: 1,
      sitesAndApps: [],
      dispatchAction: "",
      store_list: "",
      loading: Boolean,
    };
  },
  watch: {
    country() {
      this.resetData();
      this.fetchData(1);
    },
  },
  methods: {
    resetData() {
      (this.page = 1),
        (this.sitesAndApps = []),
        (this.dispatchAction = ""),
        (this.store_list = ""),
        (this.loading = Boolean);
    },
    compactNumber(value) {
      return compactNumber(value);
    },
    async fetchData(loading_page) {
      if (this.$route.name === "opera-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      if (this.$route.name === "discovery") {
        this.dispatchAction = "dsp/getCountrySitesData";
      }
      if (this.$route.name === "truecaller-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      if (this.$route.name === "phoenix-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      if (this.$route.name === "transsion-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      if (this.$route.name === "gaming-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      if (this.$route.name === "ctv-discovery") {
        this.dispatchAction = "dsp/getAppCountrySitesData";
      }
      try {
        this.loading = true;
        if (this.$route.name === "discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps = this.$store.state.dsp.kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps = this.$store.state.dsp.senegal_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps = this.$store.state.dsp.angola_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps = this.$store.state.dsp.uganda_sitesAndApps;
          } else if (this.country === "gabon") {
            this.sitesAndApps = this.$store.state.dsp.gabon_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps = this.$store.state.dsp.ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps = this.$store.state.dsp.south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps = this.$store.state.dsp.global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps = this.$store.state.dsp.nigeria_sitesAndApps;
          }
        }

        if (this.$route.name === "phoenix-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "phoenix",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_uganda_sitesAndApps;
          }else if (this.country === "gabon") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.phoenix_nigeria_sitesAndApps;
          }
        }
        if (this.$route.name === "opera-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "opera",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps = this.$store.state.dsp.opera_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.opera_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps = this.$store.state.dsp.opera_uganda_sitesAndApps;
          }else if (this.country === "gabon") {
            this.sitesAndApps = this.$store.state.dsp.opera_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps = this.$store.state.dsp.opera_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.opera_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.opera_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps = this.$store.state.dsp.opera_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.opera_nigeria_sitesAndApps;
          }
        }
        if (this.$route.name === "truecaller-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "truecaller",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_uganda_sitesAndApps;
          } else if (this.country === "gabon") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.truecaller_nigeria_sitesAndApps;
          }
        }
        if (this.$route.name === "transsion-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "transsion",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_uganda_sitesAndApps;
          }else if (this.country === "gabon") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.transsion_nigeria_sitesAndApps;
          }
        }
        if (this.$route.name === "gaming-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "gaming",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_uganda_sitesAndApps;
          }else if (this.country === "gabon") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.gaming_nigeria_sitesAndApps;
          }
        }
        if (this.$route.name === "ctv-discovery") {
          await this.$store.dispatch(this.dispatchAction, {
            app: "ctv",
            page: loading_page,
            country: this.country,
          });
          if (this.country === "kenya") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_kenya_sitesAndApps;
          } else if (this.country === "senegal") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_senegal_sitesAndApps;
          } else if (this.country === "uganda") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_uganda_sitesAndApps;
          }else if (this.country === "gabon") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_gabon_sitesAndApps;
          } else if (this.country === "angola") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_angola_sitesAndApps;
          } else if (this.country === "ethiopia") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_ethiopia_sitesAndApps;
          } else if (this.country === "south_africa") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_south_africa_sitesAndApps;
          } else if (this.country === "global") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_global_sitesAndApps;
          } else if (this.country === "nigeria") {
            this.sitesAndApps =
              this.$store.state.dsp.ctv_nigeria_sitesAndApps;
          }
        }
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },
    infiniteScrolling() {
      if (this.$route.name === "discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
            })
            .then(() => {
              if (this.country === "kenya") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.kenya_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "senegal") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.senegal_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "angola") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.angola_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "gabon") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.gabon_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "nigeria") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.nigeria_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "uganda") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.uganda_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "ethiopia") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.ethiopia_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "global") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.global_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
              if (this.country === "south_africa") {
                const temp_array = this.sitesAndApps.concat(
                  this.$store.state.dsp.south_africa_sitesAndApps
                );
                const unsorted = temp_array.filter(
                  (item, pos) => temp_array.indexOf(item) === pos
                );
                this.sitesAndApps = unsorted.sort(
                  (a, b) =>
                    parseInt(b["Filtered Impressions"]) -
                    parseInt(a["Filtered Impressions"])
                );
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "opera-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "opera",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_senegal_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_gabon_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_angola_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_nigeria_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.opera_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "truecaller-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "truecaller",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_senegal_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_gabon_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_angola_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_nigeria_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.truecaller_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "transsion-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "transsion",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_senegal_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_angola_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_gabon_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_nigeria_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.transsion_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "phoenix-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "phoenix",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_senegal_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_angola_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_gabon_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_nigeria_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.phoenix_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "gaming-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "gaming",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_senegal_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_angola_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_gabon_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_nigeria_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.gaming_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
      if (this.$route.name === "ctv-discovery") {
        setTimeout(() => {
          this.loading = true;
          this.page++;
          this.$store
            .dispatch(this.dispatchAction, {
              page: this.loading_page,
              country: this.country,
              app: "ctv",
            })
            .then(() => {
              if (this.country === "kenya") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_kenya_sitesAndApps,
                ];
              } else if (this.country === "senegal") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_senegal_sitesAndApps,
                ];
              } else if (this.country === "angola") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_angola_sitesAndApps,
                ];
              }else if (this.country === "gabon") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_gabon_sitesAndApps,
                ];
              } else if (this.country === "nigeria") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_nigeria_sitesAndApps,
                ];
              } else if (this.country === "uganda") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_uganda_sitesAndApps,
                ];
              } else if (this.country === "ethiopia") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_ethiopia_sitesAndApps,
                ];
              } else if (this.country === "global") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_global_sitesAndApps,
                ];
              } else if (this.country === "south_africa") {
                this.sitesAndApps = [
                  ...this.sitesAndApps,
                  ...this.$store.state.dsp.ctv_south_africa_sitesAndApps,
                ];
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }, 500);
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchData(this.page);
  },
  mounted() {},
  computed: {
    loading_page() {
      return this.page;
    },
  },
  props: {
    country: {
      type: String,
    },
  },
};
</script>
